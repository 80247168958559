import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
// import { setupUserflow } from '@config/userflow';
import { auth } from '@utils/auth';
import { Navigator } from '@utils/navigator';
import { UserRoles } from '@constants/enum/common';
// import { organizationSelector } from '@redux/selectors/organizationSelectors';
import LoadingIndicator from '@components/common/LoadingIndicator/LoadingIndicator';
import PrivateRoute from '@components/common/PrivateRoute';
import NotificationCenter from '@components/notificationCenter/notificationCenter';
import StudioUserDeletedPage from '@modules/PipelineStudio/pages/studioUserDeleted/studioUserDeletedPage';
import LoginComponent from '@pages/auth/Login';
import LogoutComponent from '@pages/auth/Logout';
import ErrorPage from '@pages/error/ErrorPage';

const PipelineStudioPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PipelineStudio" */ '@modules/PipelineStudio/pages/pipelineStudio/pipelineStudioPage'
    ),
);

const DeployPipelineOptionsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DeployPipelineOptions" */ '@modules/PipelineStudio/pages/deployPipelineOptions/DeployPipelineOptionsPage'
    ),
);

message.config({
  maxCount: 1,
  duration: 5,
});

const StudioRoutes: React.FC = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently, logout, loginWithRedirect, isLoading } =
    useAuth0();

  useEffect(() => {
    auth.setAccessTokenSilently(getAccessTokenSilently);
    auth.setloginWithRedirect(loginWithRedirect);
    auth.setLogout(logout);
  }, [getAccessTokenSilently, loginWithRedirect, logout]);

  useEffect(() => {
    Navigator.setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    window.location.href = 'https://cloud.deepset.ai/pipelines/builder';
  }, [isAuthenticated, user]);

  // TODO: Enable this when we have an enviroment for the standalone studio on userflow
  // useEffect(() => {
  //   if (userData.userID && organization) setupUserflow(userData, organization);
  // }, [userData.userID, organization]);

  if (isLoading) return <></>;

  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <NotificationCenter />
      <Routes>
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/logout" element={<LogoutComponent />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/user-deleted" element={<StudioUserDeletedPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <PipelineStudioPage />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN]}
            />
          }
        />
        <Route
          path="/deploy-pipeline-options"
          element={
            <PrivateRoute
              component={() => (
                <React.Suspense fallback={<LoadingIndicator />}>
                  <DeployPipelineOptionsPage />
                </React.Suspense>
              )}
              roles={[UserRoles.ADMIN]}
            />
          }
        />
        {/* redirect for 404 pages */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Sentry.ErrorBoundary>
  );
};

export default StudioRoutes;
